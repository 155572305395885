import React from "react";
import "./css/NotFound.css";

class NotFound extends React.Component {
  render() {
    return (
      <div class="text-middle">
        <h1>404</h1>
        <h3>Page Not Found</h3>
      </div>
    );
  }
}

export default NotFound;
